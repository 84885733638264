/* eslint-disable no-unused-vars */
import Google from '../../../assets/images/goggleLogo.png';
import Peacock from '../../../assets/images/peacocklogo.png';
export interface LoginFormBody {
  email: string;
  password: string;
}

export interface SocialMediaObject {
  socialMediaName: string;
  link: string;
  userName?: string;
  noOfFollowers?: string;
}

export type AccountType = 'BRAND' | 'CREATOR' | 'ADMIN';

export interface CreatorSignUpFormBody {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  socialMedia?: SocialMediaObject[];
}

export interface BrandSignUpFormBody {
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  brandName: string;
  password: string;
  brandId?:string
}

export interface ResetPasswordFormBody {
  email: string
}

export enum AccountStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED'
}

export interface UpdatePasswordFromBody {
  password: string;
  token: string;
}

export function getMobileOperatingSystem() {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};



export const StaticReview = [
  {
      _id: "665ea007526d24fe23961448",
      brandId: "665dc3cb526d24fe239611cd",
      project: [
          {
              followers: {
                  min: 300000,
                  max: 400000,
              },
              socialMediaName: "UGC Video",
              pricePerPost: 4000,
              _id: "665ea007526d24fe23961449",
          },
      ],
      rating: 5,
      isReviewPrivate: true,
      status: "APPROVED",
      review: "Super easy project! made a UGC video for new product they are releasing... didn’t even have to edit it",
      reviewerId: "665e9ddd526d24fe239612e3",
      createdAt: "",
      updatedAt: "",
      reviewerName: "ZuberCreator4 Kazi",
      brandName: "Google",
      brandLogo:Google
  },
  {
      _id: "665e9fd7526d24fe2396141d",
      brandId: "665e8a19526d24fe239612a8",
      project: [
          {
              followers: {
                  min: 500000,
                  max: 750000,
              },
              socialMediaName: "Tiktok Video",
              pricePerPost: 6000,
              _id: "665e9fd7526d24fe2396141e",
          },
      ],
      rating: 4,
      isReviewPrivate: true,
      status: "APPROVED",
      review: "Very easy brand to work with. very easy and straight forward request. only required one revision and it was a very basic...",
      reviewerId: "665e9dc0526d24fe239612dd",
      createdAt: "",
      updatedAt: "",
      reviewerName: "ZuberCreator3 Kazi",
      brandName: "Peacock",
      brandLogo:Peacock
  },
];